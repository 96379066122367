import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vue3SeamlessScroll from 'vue3-seamless-scroll';
import './permission';

import { Form, Input, Button, Row, Col } from 'ant-design-vue';

const app = createApp(App);

// 引入 ant-design-vue 组件
app.use(Form);
app.use(Input);
app.use(Button);
app.use(Row);
app.use(Col);
// 引入滚动组件
app.use(vue3SeamlessScroll);

window._AMapSecurityConfig = {
  securityJsCode: '2393c42f930f4bc270b593393b15e1bf' //密钥
};

app.use(store).use(router).mount('#app');
