import request from '@/utils/request';

// 登录
export function login(params) {
  return request({
    url: '/police/login',
    method: 'get',
    params
  });
}

// 获取验证码
export function captcha(params) {
  return request({
    url: '/login/policeCaptcha',
    method: 'get',
    params
  });
}
