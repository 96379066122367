import axios from 'axios';
import { Modal, message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import store from '@/store';
// import qs from 'qs';

// import config from '../../config/config';
// import { getToken } from '@/utils/auth';
import router from '../router';

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: config.api,
  baseURL: '/api',
  timeout: 30000
});

service.interceptors.request.use(
  config => {
    // do something before request is sent
    // config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-type': 'multipart/form-data'
    };
    config.withCredentials = true;
    if (store.getters.token) {
      config.headers = {
        token: store.getters.token,
        authorization: 'Bearer ' + store.getters.token
      };
    }

    // config.method === 'post'
    //   ? (config.data = qs.stringify({ ...config.data }))
    //   : (config.params = { ...config.params });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let status = true;
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.status && res.status !== 0 && status) {
      if (res.status === 2 || res.status === 3 || res.status === 101) {
        status = false;
        Modal.confirm({
          title: res.msg || '当前账号登录失效，请再次尝试 ',
          icon: createVNode(ExclamationCircleOutlined),
          content: '确认退出？',
          okText: '重新登录',
          cancelText: '取消',
          onOk() {
            store.dispatch('user/resetToken');
            router.replace({
              path: 'login',
              query: { redirect: router.currentRoute.fullPath }
            });
          }
        });

        let timeout = setTimeout(() => {
          status = true;
          clearTimeout(timeout);
        }, 1500);
        // return Promise.reject(new Error(res.msg || 'Error'));
      }
    }
    return res;
  },
  error => {
    console.log('err' + error); // for debug
    message({
      message: error,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
    // return error;
  }
);

export default service;
