import { login, captcha } from '@/api/login';
import {
  getToken,
  setToken,
  removeToken,
  setCookie,
  getCookie
  // removeCookie
} from '@/utils/auth';

const state = {
  token: getToken(),
  name: getCookie('username'),
  depart_name: getCookie('depart_name'),
  level: getCookie('level'),
  site_id: getCookie('site_id'),
  is_site: getCookie('is_site'),
  avatar: '',
  introduction: '',
  user_id: '',
  roles: [],
  location: [],
  area: getCookie('area'),

  alarmCount: getCookie('alarmCount'),
  alarmPort: getCookie('alarmPort'),
  alarmControl: getCookie('alarmControl'),
  alarmDuty: getCookie('alarmDuty'),
  alarmStatus: getCookie('alarmStatus'),
  ifIgnore: getCookie('ifIgnore')
};

const mutations = {
  SET_TOKEN: (state, token) => {
    setToken(token);
    state.token = token;
  },
  SET_SITE_ID: (state, data) => {
    state.site_id = data;
  },
  SET_AREA: (state, data) => {
    state.area = data;
  },
  SET_IS_SITE: (state, data) => {
    state.is_site = data;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
    state.username = name;
  },
  SET_DEPART_NAME: (state, name) => {
    state.depart_name = name;
  },
  SET_LEVEL: (state, level) => {
    state.level = level;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_LOCATION: (state, { lnt, lat }) => {
    state.location = [lnt, lat];
  },
  SET_ALARMCOUNT: (
    state,
    { alarm_count, alarm_port, alarm_control, alarm_duty, alarm_status }
  ) => {
    setCookie('alarmCount', alarm_count);
    setCookie('alarmPort', alarm_port);
    setCookie('alarmControl', alarm_control);
    setCookie('alarmDuty', alarm_duty);
    setCookie('alarmStatus', alarm_status);
    setCookie('ifIgnore', 'true');
    state.alarmCount = alarm_count;
    state.alarmPort = alarm_port;
    state.alarmControl = alarm_control;
    state.alarmDuty = alarm_duty;
    state.alarmStatus = alarm_status;
    state.ifIgnore = 'true';
  }
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then(response => {
          const { data } = response;
          // console.log(response);
          // commit('SET_TOKEN', data.token);
          try {
            // is_site等于1是加油站账号，0是监管端账号
            if (data.is_site === 1) {
              commit('SET_SITE_ID', data.site_id);
              setCookie('site_id', data.site_id);
            }
            commit('SET_IS_SITE', data.is_site);
            setCookie('is_site', data.is_site);
            commit('SET_TOKEN', data.token);
            commit('SET_LOCATION', { lnt: data.lnt, lat: data.lat });
            commit('SET_NAME', data.user_name);
            commit('SET_DEPART_NAME', data.depart_name);
            commit('SET_LEVEL', data.level);
            commit('SET_AREA', data.area);
            setToken(data.token);
            setCookie('username', data.user_name);
            setCookie('depart_name', data.depart_name);
            setCookie('level', data.level);
            setCookie('area', data.area_code);

            const {
              alarm_count = 0,
              alarm_port = 0,
              alarm_control = 0,
              alarm_duty = 0,
              alarm_status = 0
            } = data.alarm;
            commit('SET_ALARMCOUNT', {
              alarm_count: alarm_count,
              alarm_port: alarm_port,
              alarm_control: alarm_control,
              alarm_duty: alarm_duty,
              alarm_status: alarm_status
            });
          } catch (error) {
            console.log(error);
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // user logout
  // logout({ commit, state }) {
  //   const { user_id } = state;
  //   return new Promise((resolve, reject) => {
  //     logout({ user_id })
  //       .then(res => {
  //         commit('SET_TOKEN', '');
  //         // commit('SET_ROLES', []);
  //         removeToken();
  //         removeCookie('username');
  //         removeCookie('depart_name');
  //         //   resetRouter();
  //         removeCookie('area');
  //         // dispatch('tagsView/delAllViews', null, { root: true });

  //         resolve(res);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },

  captcha() {
    return new Promise((resolve, reject) => {
      captcha()
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
