import { createStore } from 'vuex';
import getters from './getters';

import app from './modules/app.js';
import user from './modules/user.js';

const store = createStore({
  modules: {
    user,
    app
  },
  getters
});

export default store;
