import router from './router';
import store from './store';
import { message } from 'ant-design-vue';
import { getToken } from '@/utils/auth';

router.beforeEach((to, from, next) => {
  const hasToken = store.getters.token || getToken();
  if (!hasToken && to.path !== '/login') {
    next({
      path: '/login'
      // query: { redirect: to.fullPath }
    });
    if (getToken()) {
      message({
        message: '登录失效',
        type: 'error'
      });
    }
  } else if (hasToken) {
    next();
  } else {
    next();
  }
});
