import { setTheme } from '@/theme/theme';
const state = {
  theme: 'dark', // light or dark
  mapStyle: 'amap://styles/grey' // 地图主题
};

const mutations = {
  CHANGE_THEME: (state, type) => {
    if (type) {
      state.theme = 'dark';
      state.mapStyle = 'amap://styles/grey';
      setTheme('dark');
    } else {
      state.theme = 'light';
      state.mapStyle = 'amap://styles/normal';
      setTheme('light');
    }
  }
};

const actions = {
  // 切换主题色
  changeTheme({ commit }, type) {
    commit('CHANGE_THEME', type);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
